import React from 'react';

const LandingPage: React.FC = () => {
    return <div style={{
        height: '100vh',
        backgroundColor: '#333130'
    }}>

    </div>
}

export default LandingPage;